import {DEV_SEARCH_URL, LOCAL_SEARCH_URL, SEARCH_URL} from './constants';

export class SearchHelper {

    /**
     * 페이지 조회 정보를 저장하는 함수
     * @param serviceType
     * @param streamId
     * @param coolIdx
     * @param env
     */
    coolPageViewForStream(serviceType: any, subServiceType: string, streamId: number, coolIdx: number, env: 'prod' | 'dev' | 'local' = 'prod') {
        if (serviceType || streamId) {
            let coolPageViewForStreamUrl = '/api/coolPageView/indexing';
            switch (env) {
                case 'local':
                    coolPageViewForStreamUrl = LOCAL_SEARCH_URL + coolPageViewForStreamUrl;
                    break;
                case 'dev':
                    coolPageViewForStreamUrl = DEV_SEARCH_URL + coolPageViewForStreamUrl;
                    break;
                case 'prod':
                default:
                    coolPageViewForStreamUrl = SEARCH_URL + coolPageViewForStreamUrl;
                    break;
            }

            const settingsData = {
                "serviceType": serviceType,
                "subServiceType": subServiceType,
                "serviceSeq": streamId,
                "url": window.location.href
            } as any;

            if (coolIdx) {
                settingsData.coolIdx = coolIdx;
            }

            const settings = {
                url: coolPageViewForStreamUrl,
                type: 'POST',
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(settingsData)
            };
            $.ajax(settings)
        }
    }

}
