import {CoolVueCommonParams} from "./model/cool-vue-common-params";

declare const document: any;

export class VueHelper {

    async settingUserParams(userInfoUrl: string, params: CoolVueCommonParams, callback?: (p: CoolVueCommonParams) => void) {
        // ajax cache 설정
        const isIe = /*@cc_on!@*/false || !!document.documentMode;
        if (isIe) {
            $.ajaxSetup({cache: false});
        }

        const settings: any = {
            url: userInfoUrl
        };

        let userInfoResp = {} as any;
        if (!params.user) {
            if (params.authorization) {
                settings.headers = {
                    Authorization: params.authorization,
                    ClientId: params.clientId
                };
                userInfoResp = await $.ajax(settings);
                params.isAuth = userInfoResp.result;
                params.user = userInfoResp.result ? userInfoResp.data : {};
            } else {
                params.isAuth = false;
                params.user = {};
            }
        }
        if (callback) {
            callback(params);
        }

        return params;
    }

}
