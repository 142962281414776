import moment from 'moment'

/**
 * DateHelper
 *
 * moment 라이브러리에 의존함
 */
export class DateHelper {
    /**
     * date 를 하루의 끝시간으로 설정
     * @param {string} dateStr
     * @returns {string}
     */
    setEndDateToISOString(dateStr: string) {
        const date = moment(dateStr);
        const h = 23;
        const m = 59;
        const s = 59;
        date.hour(h);
        date.minute(m);
        date.second(s);

        return date.toISOString();
    }
}
