import {DateHelper} from './date-helper'
import {FileHelper} from "./file-helper";
import {Login} from './login'
import {UrlHelper} from './url-helper'

export class Factory {
    static newInstanceLogin() {
        return new Login();
    }

    static newInstanceUrlHelper() {
        return new UrlHelper();
    }

    static newInstanceDateHelper() {
        return new DateHelper();
    }

    static newInstanceFileHelper() {
        return new FileHelper();
    }
}
