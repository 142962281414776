import {
    COOLSCHOOL_URL,
    DEV_COOLSCHOOL_URL,
    DEV_MEMBER_URL,
    DEV_SEARCH_URL,
    Env, LOCAL_COOLSCHOOL_URL,
    LOCAL_MEMBER_URL,
    LOCAL_SEARCH_URL,
    MEMBER_URL,
    SEARCH_URL,
    ServerName
} from "./constants";

export class EnvHelper {

    getUrl(env: Env, serverName: ServerName, fnUrl?: (url: string) => string): string {
        let url = '';
        switch (env) {
            case Env.PROD:
                switch (serverName) {
                    case ServerName.MEMBER:
                        url = MEMBER_URL;
                        break;
                    case ServerName.SEARCH:
                        url = SEARCH_URL;
                        break;
                    case ServerName.COOLSCHOOL:
                        url = COOLSCHOOL_URL;
                        break;
                }
                break;
            case Env.DEV:
                switch (serverName) {
                    case ServerName.MEMBER:
                        url = DEV_MEMBER_URL;
                        break;
                    case ServerName.SEARCH:
                        url = DEV_SEARCH_URL;
                        break;
                    case ServerName.COOLSCHOOL:
                        url = DEV_COOLSCHOOL_URL;
                        break;
                }
                break;
            case Env.LOCAL:
                switch (serverName) {
                    case ServerName.MEMBER:
                        url = LOCAL_MEMBER_URL;
                        break;
                    case ServerName.SEARCH:
                        url = LOCAL_SEARCH_URL;
                        break;
                    case ServerName.COOLSCHOOL:
                        url = LOCAL_COOLSCHOOL_URL;
                        break;
                }
                break;
        }
        if(fnUrl) {
            return fnUrl(url);
        }

        return url;
    }

}
