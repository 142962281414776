export class UrlHelper {
    /**
     * location 의 param 을 가져온다
     * @param {string} key
     * @returns {string} value
     */
    getQueryParam(key: string) {
        return this.getQueryParams()[key] || '';
    }

    /**
     * location params 정보를 가져온다
     * @param {string} queryString
     * @returns {any} params
     */
    private getQueryParams(queryString = '') {
        let qs = queryString;
        qs = qs || document.location.href.split('?')[1] || document.location.search;
        qs = qs.split('+').join(' ');

        const params: any = {};
        let tokens;
        const re = /[?&]?([^=]+)=([^&]*)/g;
        const sec = 2;

        // noinspection TsLint
        // tslint:disable-next-line
        while ((tokens = re.exec(qs))) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[sec]);
        }

        return params;
    }

    /**
     * 하나의 hash 를 가져온다
     * @param url
     */
    getHashSingleValue(url: string) {
        const hash = url.indexOf('#');
        if (hash === -1) {
            return '';
        }

        return url.substring(hash);
    }
}
